import 'dayjs/locale/it';

import { Button, Group, Stack, Text, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { closeModal } from '@mantine/modals';
import { notifications } from '@mantine/notifications';

import {
  CreateModelRequestBody,
  Model,
  UpdateModelRequest,
  useCreateModelMutation,
  useUpdateModelMutation,
} from '@api/models.api';

import { handleSubmitError } from '../utils/forms';
import BrandsSelector from './BrandsSelector';
import ErroCodesMultiSelector from './ErrorCodesMultiSelector';

export const UPSERT_MODEL_FORM_MODAL_ID = 'upsertModel';

interface UpsertModelFormProps {
  model?: Model;
}

export default function UpsertModelForm({ model }: UpsertModelFormProps) {
  const initialValues = {
    name: model?.name || '',
    brandName: model?.brandName || '',
    errorCodes: model?.errorCodes || [],
  };

  const form = useForm({
    initialValues,
  });

  const [createModel, { isLoading: isLoadingCreate }] =
    useCreateModelMutation();
  const [updateModel, { isLoading: isLoadingUpdate }] =
    useUpdateModelMutation();

  const onCancelClick = () => {
    closeModal(UPSERT_MODEL_FORM_MODAL_ID);
  };

  const onSubmit = async (values: typeof initialValues) => {
    try {
      if (model) {
        const data: UpdateModelRequest = {
          name: model.name,
          body: {
            name: values.name,
            brandName: values.brandName,
            errorCodes: values.errorCodes,
          },
        };

        await updateModel(data).unwrap();

        notifications.show({
          title: 'Modello modificato',
          message: 'Modello modificato con successo.',
        });
      } else {
        const data: CreateModelRequestBody = {
          name: values.name,
          brandName: values.brandName,
          errorCodes: values.errorCodes,
        };

        await createModel(data).unwrap();

        notifications.show({
          title: 'Modello creato',
          message: 'Nuovo modello creato con successo.',
        });
      }

      closeModal(UPSERT_MODEL_FORM_MODAL_ID);
    } catch (e) {
      handleSubmitError(e, form);
    }
  };

  return (
    <form onSubmit={form.onSubmit(onSubmit)}>
      <Stack>
        <TextInput
          required
          data-autofocus
          label="Nome"
          {...form.getInputProps('name')}
        />
        <BrandsSelector
          required
          label="Marca"
          {...form.getInputProps('brandName')}
        />
        <ErroCodesMultiSelector
          label="Codici di errore associati"
          {...form.getInputProps('errorCodes')}
        />
      </Stack>

      {form.errors.general && (
        <Text c="red" size="xs">
          {form.errors.general}
        </Text>
      )}

      <Group justify="right" mt="xl">
        <Button
          onClick={onCancelClick}
          variant="default"
          loading={isLoadingCreate || isLoadingUpdate}
        >
          Annulla
        </Button>
        <Button type="submit" loading={isLoadingCreate || isLoadingUpdate}>
          Conferma
        </Button>
      </Group>
    </form>
  );
}

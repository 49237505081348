import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { SigninRequest, UserPatchRequest } from '@interfaces/auth.interface';

import env from '@config/env';

import { useLogoutMutation, useSigninMutation } from '@api/auth.api';
import api from '@api/index';
import { usePatchUserMutation } from '@api/users.api';

import {
  logout as logoutAction,
  selectCurrentUser,
  setCredentials,
} from '@slices/auth.slice';

const useAuth = () => {
  const dispatch = useDispatch();

  const user = useSelector(selectCurrentUser);

  const [signinMutation, { isLoading: isLoadingSignin }] = useSigninMutation();
  const [logoutMutation, { isLoading: isLoadingLogout }] = useLogoutMutation();
  const [patchMutation, { isLoading: isLoadingPatch }] = usePatchUserMutation();

  const signin = useCallback(
    async (data: SigninRequest) => {
      const user = await signinMutation(data).unwrap();

      if (user) {
        if (env.MODE === 'production') {
          const { H } = await import('highlight.run');
          H.identify(user.email, {
            id: user.id,
            name: user.name,
          });
        }
      }

      dispatch(setCredentials({ user }));
    },
    [dispatch, signinMutation],
  );

  const logout = useCallback(async () => {
    await logoutMutation().unwrap();

    dispatch(api.util.resetApiState());
    dispatch(logoutAction());
  }, [dispatch, logoutMutation]);

  const patch = useCallback(
    async (body: UserPatchRequest) => {
      const patchedUser = await patchMutation(body).unwrap();
      const updatedUser = {
        ...user,
        ...patchedUser,
      };
      dispatch(setCredentials({ user: updatedUser }));
    },
    [dispatch, patchMutation, user],
  );

  return useMemo(
    () => ({
      user,
      isAuthenticated: user !== null,
      signin,
      patch,
      logout,
      isLoading: isLoadingSignin || isLoadingLogout || isLoadingPatch,
    }),
    [
      user,
      signin,
      patch,
      logout,
      isLoadingSignin,
      isLoadingLogout,
      isLoadingPatch,
    ],
  );
};

export default useAuth;

import { ReactNode } from 'react';

import { Box, Stack } from '@mantine/core';

import logo from '@assets/images/logo.png';

import classes from './GuestLayout.module.css';

interface GuestLayoutProps {
  size?: number;
  children: ReactNode;
}

export default function GuestLayout({
  size = 420,
  children,
}: GuestLayoutProps) {
  return (
    <Stack align="center" p="md" my="xl">
      <img src={logo} alt="Logo Tuoteam" className={classes.logo} />
      <Box maw={size} w="100%">
        {children}
      </Box>
    </Stack>
  );
}

import { RepairsStatuses } from '@api/repairs.api';

export const statusMapper = (
  status: RepairsStatuses,
): { label: string; color: string; notifyToClient: boolean } => {
  switch (status) {
    case 'a0_pending':
      return {
        label: 'In attesa di conferma',
        color: 'orange',
        notifyToClient: false,
      };
    case 'inserito':
      return { label: 'Inserita', color: 'blue', notifyToClient: true };
    case 'arrivato':
      return {
        label: 'Macchina arrivata',
        color: 'blue',
        notifyToClient: true,
      };
    case 'consegnato':
      return {
        label: 'Macchina consegnata',
        color: 'blue',
        notifyToClient: true,
      };
    case 'ritiratoPressoCliente':
      return {
        label: 'Macchina ritirata presso il cliente',
        color: 'blue',
        notifyToClient: true,
      };
    case 'inDiagnosi':
      return { label: 'In diagnosi', color: 'blue', notifyToClient: true };
    case 'inAttesaIndicazioniCliente':
      return {
        label: 'In attesa di indicazione dal cliente',
        color: 'blue',
        notifyToClient: true,
      };
    case 'inAttesaApprovazionePreventivo':
      return {
        label: 'In attesa di approvazione preventivo',
        color: 'blue',
        notifyToClient: true,
      };
    case 'inRiparazione':
      return { label: 'In riparazione', color: 'blue', notifyToClient: true };
    case 'speditoARiparatoreEsterno':
      return {
        label: 'Spedita a riparatore esterno',
        color: 'blue',
        notifyToClient: false,
      };
    case 'inAttesaComponenti':
      return {
        label: 'In attesa di componenti',
        color: 'blue',
        notifyToClient: false,
      };
    case 'inAttesaInverter':
      return {
        label: 'In attesa di inverter',
        color: 'blue',
        notifyToClient: false,
      };
    case 'inAttesaOffertaFornitoreComponenti':
      return {
        label: 'In attesa offerta fornitore componenti',
        color: 'blue',
        notifyToClient: false,
      };
    case 'inAttesaOffertaFornitoreInverter':
      return {
        label: 'In attesa offerta fornitore inverter',
        color: 'blue',
        notifyToClient: false,
      };
    case 'riparatoInAttesaCollaudo':
      return {
        label: 'Riparata e in attesa di collaudo',
        color: 'blue',
        notifyToClient: true,
      };
    case 'inCollaudo':
      return { label: 'In collaudo', color: 'blue', notifyToClient: true };
    case 'riparazioneECollaudoConclusi':
      return {
        label: 'Riparata e collaudata',
        color: 'blue',
        notifyToClient: true,
      };
    case 'inAttesaPagamento':
      return {
        label: 'In attesa di pagamento',
        color: 'blue',
        notifyToClient: true,
      };
    case 'inAttesaRottamazione':
      return {
        label: 'In attesa di rottamazione',
        color: 'blue',
        notifyToClient: false,
      };
    case 'prontoPerSpedizione':
      return {
        label: 'Pronta per la spedizione',
        color: 'blue',
        notifyToClient: true,
      };
    case 'prontoPerRitiro':
      return {
        label: 'Pronta per il ritiro',
        color: 'blue',
        notifyToClient: true,
      };
    case 'spedito':
      return { label: 'Spedita', color: 'blue', notifyToClient: true };
    case 'ritiratoPressoNostraSede':
      return {
        label: 'Ritirata presso nostra sede',
        color: 'blue',
        notifyToClient: true,
      };
  }
};

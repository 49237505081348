import { ReactNode } from 'react';
import { Link } from 'react-router-dom';

import {
  Button,
  Container,
  Group,
  LoadingOverlay,
  ScrollArea,
  Title,
} from '@mantine/core';
import { IconChevronLeft } from '@tabler/icons-react';

import Footer from '@components/layout/Footer';

import BottomNavbar from '../bottomNavbar/BottomNavbar';
import Header from '../header/Header';
import Sidebar from '../sidebar/Sidebar';
import classes from './AuthenticatedLayout.module.css';

interface LayoutProps {
  children?: ReactNode;
  title?: string;
  backLink?: {
    title: string;
    to: string;
  };
  loading?: boolean;
}
export default function AuthenticatedLayout({
  children,
  title,
  backLink,
  loading,
}: LayoutProps) {
  return (
    <>
      <Header />
      <Group align="start" gap={0} wrap="nowrap" style={{ height: '100vh' }}>
        <Sidebar />
        <ScrollArea className={classes.scrollArea}>
          <LoadingOverlay visible={loading || false} />
          <Container size={1400} className={classes.container}>
            <div style={{ flexGrow: 1 }}>
              {backLink && (
                <Button
                  component={Link}
                  to={backLink.to}
                  variant="light"
                  size="xs"
                  mb="lg"
                  leftSection={<IconChevronLeft />}
                >
                  {backLink.title}
                </Button>
              )}
              {title && (
                <Title order={1} mb="lg">
                  {title}
                </Title>
              )}
              {children}
            </div>
            <Footer />
          </Container>
        </ScrollArea>
      </Group>
      <BottomNavbar />
    </>
  );
}

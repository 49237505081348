import { useEffect, useState } from 'react';
import { useOutletContext, useSearchParams } from 'react-router-dom';

import {
  ActionIcon,
  Anchor,
  Badge,
  Button,
  Grid,
  Group,
  Loader,
  Paper,
  Select,
  Space,
  Stack,
  Text,
  Tooltip,
} from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { openConfirmModal, openModal } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import {
  IconListCheck,
  IconPencil,
  IconPlus,
  IconStatusChange,
  IconTrash,
  IconX,
} from '@tabler/icons-react';
import { dateToDateHourString } from '@utils/date';
import { statusMapper } from '@utils/mappers';

import { SortOrder } from '@domain/types';

import { isApiError } from '@api/index';
import {
  GetRepairsRequestParams,
  Repair,
  RepairsStatuses,
  repairStatusesArray,
  useCountRepairsQuery,
  useDeleteRepairMutation,
  useGetRepairsQuery,
  useUpdateRepairStatusMutation,
} from '@api/repairs.api';

import PaginationRow from '@components/PaginationRow';
import RepairStatusesHistory from '@components/RepairStatusesHistory';
import SearchBar from '@components/SearchBar';
import UpdateRepairStatusForm, {
  UPDATE_REPAIR_STATUS_FORM_MODAL_ID,
} from '@components/UpdateRepairStatusForm';
import UpsertRepairForm, {
  UPSERT_REPAIR_FORM_MODAL_ID,
} from '@components/UpsertRepairForm';
import { LayoutContextType } from '@components/layout/Layout';

export default function Repairs() {
  // ==========================================================================
  // General
  // ==========================================================================

  // ==========================================================================
  // State
  // ==========================================================================
  const [searchParams] = useSearchParams();

  const [filters, setFilters] = useState<GetRepairsRequestParams>({
    page: +(searchParams.get('page') || 1),
    pageLength: +(searchParams.get('pageLength') || 10),
    searchQuery: searchParams.get('search') || undefined,
  });

  const [searchQuery] = useDebouncedValue(filters.searchQuery, 200, {
    leading: true,
  });

  // ==========================================================================
  // Api
  // ==========================================================================
  const { data: repairsCount = { count: 0 }, isLoading: isLoadingCount } =
    useCountRepairsQuery({ searchQuery, status: filters.status });

  const { data = [], isLoading } = useGetRepairsQuery({
    ...filters,
    searchQuery,
  });

  const [updateRepairStatus, { isLoading: isLoadingUpdate }] =
    useUpdateRepairStatusMutation();
  const [deleteRepair] = useDeleteRepairMutation();

  // ==========================================================================
  // Handlers
  // ==========================================================================
  const handleCreateRepairClick = () => {
    openModal({
      modalId: UPSERT_REPAIR_FORM_MODAL_ID,
      title: 'Inserisci riparazione',
      children: <UpsertRepairForm />,
      size: 'lg',
    });
  };

  const handleEditRepairClick = (repair: Repair) => {
    openModal({
      modalId: UPSERT_REPAIR_FORM_MODAL_ID,
      title: 'Modifica riparazione',
      children: <UpsertRepairForm repair={repair} />,
      size: 'lg',
    });
  };

  const handleEditRepairStatusClick = (repair: Repair) => {
    openModal({
      modalId: UPDATE_REPAIR_STATUS_FORM_MODAL_ID,
      title: 'Modifica stato',
      children: <UpdateRepairStatusForm repair={repair} />,
    });
  };

  const handleDeleteRepairClick = (id: number) => {
    openConfirmModal({
      title: 'Elimina riparazione',
      children:
        "Stai per eliminare la riparazione. L'operazione non è reversibile.\nContinuare?",
      confirmProps: { color: 'red' },
      labels: { confirm: 'Conferma', cancel: 'Annulla' },
      onConfirm: async () => {
        try {
          await deleteRepair(id).unwrap();

          showNotification({
            title: 'Riparazione eliminata',
            message: 'Riparazione eliminata con successo',
          });
        } catch (e) {
          if (isApiError(e)) {
            showNotification({
              title: 'Errore',
              message: e.data.message,
              color: 'red',
              icon: <IconX />,
            });
          } else {
            showNotification({
              title: 'Errore',
              message: 'Errore sconosciuto',
              color: 'red',
              icon: <IconX />,
            });
            console.error(e);
          }
        }
      },
    });
  };

  const handleConfirmClick = async (id: number) => {
    await updateRepairStatus({
      id,
      body: { status: 'inserito', notifyToClient: false },
    });
  };

  const handleRepairStatusesHistoryClick = (id: number) => {
    openModal({
      modalId: UPSERT_REPAIR_FORM_MODAL_ID,
      title: `Storico stati riparazione # ${id}`,
      children: <RepairStatusesHistory repairId={id} />,
    });
  };

  // ==========================================================================
  // Render
  // ==========================================================================
  const { setLayoutProps } = useOutletContext<LayoutContextType>();

  useEffect(() => {
    setLayoutProps({ title: 'Riparazioni' });
  }, [setLayoutProps]);

  const totalPages = Math.round(repairsCount.count / (filters.pageLength || 0));

  return (
    <>
      <Group mb="xl" justify="space-between">
        <Group>
          <SearchBar
            placeholder="Ricerca per s/n, nome o cognome cliente"
            value={filters.searchQuery}
            onChange={(value) =>
              setFilters({ ...filters, searchQuery: value, page: 1 })
            }
          />
          <Select
            variant="filled"
            w={{ base: '100%', md: 'auto' }}
            placeholder="Filtra per stato"
            data={repairStatusesArray.map((status) => ({
              value: status,
              label: statusMapper(status).label,
            }))}
            value={filters.status || null}
            onChange={(status) =>
              setFilters((f) => ({
                ...f,
                status: (status || undefined) as RepairsStatuses,
                page: 1,
              }))
            }
          />
          <Select
            variant="filled"
            w={{ base: '100%', md: 'auto' }}
            placeholder="Ordina per"
            data={[
              {
                value: 'asc',
                label: 'Data (crescente)',
              },
              {
                value: 'desc',
                label: 'Data (decrescente)',
              },
            ]}
            value={filters.sortOrder || null}
            onChange={(value) =>
              setFilters((f) => ({
                ...f,
                sortBy: value ? 'createdAt' : undefined,
                sortOrder: (value || undefined) as SortOrder,
              }))
            }
          />
        </Group>
        <Button onClick={handleCreateRepairClick} leftSection={<IconPlus />}>
          Inserisci lavorazione
        </Button>
      </Group>

      {isLoading || isLoadingCount ? (
        <Loader />
      ) : data.length === 0 ? (
        <Text fs="italic" fw="bold">
          Nessuna riparazione trovata per i filtri selezionati
        </Text>
      ) : (
        <>
          <Stack>
            {data.map((repair) => (
              <Paper
                styles={(theme) => ({
                  root: { backgroundColor: theme.colors.gray[1] },
                })}
                key={repair.id}
                // withBorder
                py={{ base: 'md', md: 'lg' }}
                px={{ base: 'lg', md: 'xl' }}
                // shadow="xs"
              >
                <Group justify="space-between">
                  <Group align="center">
                    <Text size="xl" fz="1.5rem" fw="600">
                      # {repair.id}
                    </Text>
                    <Space w="0.3rem" />
                    <Badge
                      size="lg"
                      color={statusMapper(repair.currentStatus).color}
                    >
                      {statusMapper(repair.currentStatus).label}
                    </Badge>
                    {repair.currentStatus === 'a0_pending' ? (
                      <>
                        <Button
                          onClick={() => handleConfirmClick(repair.id)}
                          loading={isLoadingUpdate}
                        >
                          Conferma inserimento
                        </Button>
                      </>
                    ) : (
                      <>
                        <Tooltip label="Modifica stato">
                          <ActionIcon
                            onClick={() => handleEditRepairStatusClick(repair)}
                          >
                            <IconStatusChange />
                          </ActionIcon>
                        </Tooltip>
                        <Tooltip label="Storico stati">
                          <ActionIcon
                            variant="light"
                            onClick={() =>
                              handleRepairStatusesHistoryClick(repair.id)
                            }
                          >
                            <IconListCheck />
                          </ActionIcon>
                        </Tooltip>
                      </>
                    )}
                  </Group>
                  <Group gap="xs">
                    <Text fw="600">
                      {dateToDateHourString(new Date(repair.createdAt))}
                    </Text>
                    <Tooltip label="Modifica informazioni">
                      <ActionIcon
                        variant="light"
                        onClick={() => handleEditRepairClick(repair)}
                      >
                        <IconPencil />
                      </ActionIcon>
                    </Tooltip>
                    <Tooltip label="Elimina riparazione">
                      <ActionIcon
                        color="red"
                        variant="light"
                        onClick={() => handleDeleteRepairClick(repair.id)}
                      >
                        <IconTrash />
                      </ActionIcon>
                    </Tooltip>
                  </Group>
                </Group>
                <Grid>
                  <Grid.Col span={{ lg: 6 }}>
                    <Group gap="xs" mt="md">
                      <Text fw="500">Modello: </Text>
                      <Text fw="300">{repair.modelName}</Text>
                    </Group>
                    <Group gap="xs">
                      <Text fw="500">Codice di errore: </Text>
                      <Text fw="300">{repair.errorCode || '-'}</Text>
                    </Group>
                    <Group gap="xs">
                      <Text fw="500">Numero seriale: </Text>
                      <Text fw="300">{repair.serialNumber}</Text>
                    </Group>
                    <Group gap="xs">
                      <Text fw="500">Nota: </Text>
                      <Text fw="300">{repair.note || '-'}</Text>
                    </Group>
                  </Grid.Col>
                  <Grid.Col span={{ lg: 6 }}>
                    <Group gap="xs" mt="md">
                      <Text fw="500">Cliente: </Text>
                      <Text fw="300">
                        {repair.clientFirstName} {repair.clientLastName}
                      </Text>
                    </Group>
                    <Group gap="xs">
                      <Text fw="500">Email cliente: </Text>
                      <Text fw="300">
                        <Anchor href={`mailto:${repair.clientEmail}`}>
                          {repair.clientEmail}
                        </Anchor>
                      </Text>
                    </Group>
                    <Group gap="xs">
                      <Text fw="500">Telefono cliente: </Text>
                      <Text fw="300">
                        <Anchor href={`tel:${repair.clientPhone}`}>
                          {repair.clientPhone}
                        </Anchor>
                      </Text>
                    </Group>
                    <Group gap="xs">
                      <Text fw="500">Messaggio: </Text>
                      <Text fw="300">{repair.message || '-'}</Text>
                    </Group>
                  </Grid.Col>
                </Grid>
              </Paper>
            ))}
          </Stack>
          <PaginationRow
            isLoading={isLoadingCount}
            page={filters.page || 0}
            pageLength={filters.pageLength || 0}
            totalPages={totalPages}
            onPageChange={(page) => setFilters((f) => ({ ...f, page }))}
            onPageLengthChange={(pageLength) =>
              setFilters((f) => ({ ...f, pageLength }))
            }
          />
        </>
      )}
    </>
  );
}

import { ReactNode } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import useAuth from '@hooks/useAuth';

import { LOGIN_PATH } from '@routes/router';

export default function PrivateRoute({ element }: { element: ReactNode }) {
  const location = useLocation();

  const { isAuthenticated } = useAuth();

  return isAuthenticated ? (
    element
  ) : (
    <Navigate to={`${LOGIN_PATH}/?r=${location.pathname}`} />
  );
}

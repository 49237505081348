const env = {
  MODE: import.meta.env.MODE,
  VERSION: import.meta.env.VERSION!,
  API_URL: import.meta.env.VITE_API_URL
    ? import.meta.env.VITE_API_URL
    : `${window.location.protocol}//${window.location.hostname}:5000/api`,
  API_LOGIN_PATH: import.meta.env.VITE_API_LOGIN_PATH,
  API_REFRESH_PATH: import.meta.env.VITE_API_REFRESH_PATH,
  BASE_URL: import.meta.env.VITE_BASE_URL,

  HIGHLIGHT_PROJECT_ID: import.meta.env.VITE_HIGHLIGHT_PROJECT_ID,
};

export default env;

import { DataTable, DataTableSortStatus } from 'mantine-datatable';
import { useEffect, useState } from 'react';
import { useOutletContext, useSearchParams } from 'react-router-dom';

import { User } from '@interfaces/auth.interface';
import { ActionIcon, Button, Group, Switch, Tooltip } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { openConfirmModal, openModal } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import { IconPlus, IconTrash, IconX } from '@tabler/icons-react';

import { isApiError } from '@api/index';
import {
  GetUsersRequestParams,
  GetUsersSortBy,
  useActivateUserMutation,
  useCountUsersQuery,
  useDeactivateUserMutation,
  useDeleteUserMutation,
  useGetUsersQuery,
} from '@api/users.api';

import useAuth from '@hooks/useAuth';

import CreatePasswordlessUserModal from '@components/CreatePasswordlessUserModal';
import SearchBar from '@components/SearchBar';
import { LayoutContextType } from '@components/layout/Layout';

export default function Users() {
  // ==========================================================================
  // General
  // ==========================================================================
  const { user } = useAuth();

  // ==========================================================================
  // State
  // ==========================================================================
  const [searchParams] = useSearchParams();

  const [filters, setFilters] = useState<GetUsersRequestParams>({
    page: +(searchParams.get('page') || 1),
    pageLength: +(searchParams.get('pageLength') || 10),
    searchQuery: searchParams.get('search') || undefined,
    sortBy: 'id',
    sortOrder: 'asc',
  });

  const [searchQuery] = useDebouncedValue(filters.searchQuery, 200, {
    leading: true,
  });

  const [sortStatus, setSortStatus] = useState<DataTableSortStatus<User>>({
    columnAccessor: 'id',
    direction: 'asc',
  });

  useEffect(() => {
    if (sortStatus) {
      setFilters((f) => ({
        ...f,
        sortBy: sortStatus.columnAccessor as GetUsersSortBy,
        sortOrder: sortStatus.direction,
      }));
    }
  }, [sortStatus]);

  // ==========================================================================
  // Api
  // ==========================================================================
  const { data: userCount = { count: 0 }, isLoading: isLoadingCount } =
    useCountUsersQuery({ searchQuery });

  const { data = [], isLoading } = useGetUsersQuery({
    ...filters,
    searchQuery,
  });

  const [activateUser, { isLoading: isLoadingActivateUser }] =
    useActivateUserMutation();
  const [deactivateUser, { isLoading: isLoadingDeactivateUser }] =
    useDeactivateUserMutation();
  const [deleteUser] = useDeleteUserMutation();

  // ==========================================================================
  // Handlers
  // ==========================================================================
  const handleCreateUserClick = () => {
    openModal({
      title: 'Crea utente',
      children: <CreatePasswordlessUserModal />,
    });
  };

  const handleDeleteUserClick = (id: number) => {
    openConfirmModal({
      title: 'Elimina utente',
      children:
        "Stai per eliminare l'utente. L'operazione non è reversibile.\nContinuare?",
      confirmProps: { color: 'red' },
      labels: { confirm: 'Conferma', cancel: 'Annulla' },
      onConfirm: async () => {
        try {
          await deleteUser(id).unwrap();

          showNotification({
            title: 'Utente eliminato',
            message: 'Utente eliminato con successo',
          });
        } catch (e) {
          if (isApiError(e)) {
            showNotification({
              title: 'Errore',
              message: e.data.message,
              color: 'red',
              icon: <IconX />,
            });
          } else {
            showNotification({
              title: 'Errore',
              message: 'Errore sconosciuto',
              color: 'red',
              icon: <IconX />,
            });
            console.error(e);
          }
        }
      },
    });
  };

  // ==========================================================================
  // Render
  // ==========================================================================
  const { setLayoutProps } = useOutletContext<LayoutContextType>();

  useEffect(() => {
    setLayoutProps({ title: 'Utenti amministratori' });
  }, [setLayoutProps]);

  return (
    <>
      <Group mb="lg" justify="space-between">
        <Group>
          <SearchBar
            placeholder="Ricerca per nome/email"
            value={filters.searchQuery}
            onChange={(value) => setFilters({ ...filters, searchQuery: value })}
          />
        </Group>
        <Button onClick={handleCreateUserClick} leftSection={<IconPlus />}>
          Crea utente
        </Button>
      </Group>
      <DataTable
        /* General */
        striped
        noRecordsText="Nessun utente trovato"
        /* Data */
        fetching={isLoading || isLoadingCount}
        columns={[
          {
            accessor: 'active',
            title: 'Attivo',
            width: '0%',
            render: (record) => (
              <Tooltip
                label={
                  record.id === user?.id
                    ? 'Non puoi disabilitare il tuo account'
                    : record.activationTokenCreatedAt
                      ? "L'utente non ha ancora attivato il suo account"
                      : record.active
                        ? 'Disabilita'
                        : 'Abilita'
                }
              >
                <div>
                  <Switch
                    disabled={
                      record.id === user?.id ||
                      record.activationTokenCreatedAt !== null ||
                      isLoadingActivateUser ||
                      isLoadingDeactivateUser
                    }
                    checked={record.active}
                    onClick={
                      record.active
                        ? () => deactivateUser(record.id)
                        : () => activateUser(record.id)
                    }
                  />
                </div>
              </Tooltip>
            ),
          },
          { accessor: 'id', sortable: true, width: '0%' },
          {
            accessor: 'name',
            title: 'Nome',
            sortable: true,
          },
          {
            accessor: 'email',
            sortable: true,
          },
          {
            accessor: 'actions',
            title: '',
            textAlign: 'right',
            width: '0%',
            render: (record) =>
              record.id !== user?.id && (
                <Tooltip label="Elimina utente">
                  <ActionIcon
                    color="red"
                    variant="subtle"
                    onClick={() => handleDeleteUserClick(record.id)}
                  >
                    <IconTrash />
                  </ActionIcon>
                </Tooltip>
              ),
          },
        ]}
        records={data}
        /* Sorting */
        sortStatus={sortStatus}
        onSortStatusChange={setSortStatus}
        /* Pagination */
        page={filters.page!}
        recordsPerPage={filters.pageLength!}
        totalRecords={userCount.count}
        paginationText={() => ''}
        recordsPerPageLabel=""
        recordsPerPageOptions={[10, 20, 50]}
        onPageChange={(newPage) => setFilters({ ...filters, page: newPage })}
        onRecordsPerPageChange={(newPageLength) =>
          setFilters({ ...filters, pageLength: newPageLength, page: 1 })
        }
      />
    </>
  );
}

import { Button, MantineThemeOverride, Skeleton } from '@mantine/core';

const theme: MantineThemeOverride = {
  fontFamily: 'Kanit',

  headings: {
    sizes: {
      h1: {
        fontWeight: '600',
      },
    },
  },

  colors: {
    brand: [
      '#ffe9e9',
      '#ffd1d1',
      '#faa1a1',
      '#f56e6e',
      '#f24342',
      '#f12927',
      '#f01a18',
      '#d60d0d',
      '#bf0309',
      '#a80005',
    ],
  },
  primaryColor: 'brand',

  black: '#2c2f3a',

  components: {
    Button: Button.extend({
      styles: {
        label: {
          fontWeight: 500,
        },
      },
    }),
    Skeleton: Skeleton.extend({
      styles: {
        root: {
          width: 'max-content',
        },
      },
    }),
  },
};

export default theme;

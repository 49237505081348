import 'dayjs/locale/it';

import { Button, Group, Stack, Text, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { closeModal } from '@mantine/modals';
import { notifications } from '@mantine/notifications';

import {
  Brand,
  CreateBrandRequestBody,
  UpdateBrandRequest,
  useCreateBrandMutation,
  useUpdateBrandMutation,
} from '@api/brands.api';

import { handleSubmitError } from '../utils/forms';

export const UPSERT_BRAND_FORM_MODAL_ID = 'upsertBrand';

interface UpsertBrandFormProps {
  brand?: Brand;
}

export default function UpsertBrandForm({ brand }: UpsertBrandFormProps) {
  const initialValues = {
    name: brand?.name || '',
  };

  const form = useForm({
    initialValues,
  });

  const [createBrand, { isLoading: isLoadingCreate }] =
    useCreateBrandMutation();
  const [updateBrand, { isLoading: isLoadingUpdate }] =
    useUpdateBrandMutation();

  const onCancelClick = () => {
    closeModal(UPSERT_BRAND_FORM_MODAL_ID);
  };

  const onSubmit = async (values: typeof initialValues) => {
    try {
      if (brand) {
        const data: UpdateBrandRequest = {
          name: brand.name,
          body: {
            name: values.name,
          },
        };

        await updateBrand(data).unwrap();

        notifications.show({
          title: 'Marca modificata',
          message: 'Marca modificata con successo.',
        });
      } else {
        const data: CreateBrandRequestBody = {
          name: values.name,
        };

        await createBrand(data).unwrap();

        notifications.show({
          title: 'Marca creata',
          message: 'Nuova marca creata con successo.',
        });
      }

      closeModal(UPSERT_BRAND_FORM_MODAL_ID);
    } catch (e) {
      handleSubmitError(e, form);
    }
  };

  return (
    <form onSubmit={form.onSubmit(onSubmit)}>
      <Stack>
        <TextInput
          required
          label="Nome"
          data-autofocus
          {...form.getInputProps('name')}
        />
      </Stack>

      {form.errors.general && (
        <Text c="red" size="xs">
          {form.errors.general}
        </Text>
      )}

      <Group justify="right" mt="xl">
        <Button
          onClick={onCancelClick}
          variant="default"
          loading={isLoadingCreate || isLoadingUpdate}
        >
          Annulla
        </Button>
        <Button type="submit" loading={isLoadingCreate || isLoadingUpdate}>
          Conferma
        </Button>
      </Group>
    </form>
  );
}

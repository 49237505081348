import { Navigate, useSearchParams } from 'react-router-dom';

import useAuth from '@hooks/useAuth';

import LoginForm from '@components/LoginForm';

export default function Login() {
  // ==========================================================================
  // General
  // ==========================================================================
  const [searchParams] = useSearchParams();
  const { isAuthenticated } = useAuth();

  // ==========================================================================
  // Render
  // ==========================================================================
  return isAuthenticated ? (
    <Navigate to={searchParams.get('r') || '/'} replace={true} />
  ) : (
    <LoginForm />
  );
}

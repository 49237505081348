import 'dayjs/locale/it';

import { useState } from 'react';

import {
  Button,
  Divider,
  Grid,
  Group,
  Select,
  Text,
  TextInput,
  Textarea,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { closeModal } from '@mantine/modals';
import { notifications } from '@mantine/notifications';

import { useGetErrorCodesQuery } from '@api/errorCodes.api';
import { useGetModelsQuery } from '@api/models.api';
import {
  CreateRepairRequestBody,
  Repair,
  UpdateRepairRequest,
  useCreateRepairMutation,
  useUpdateRepairMutation,
} from '@api/repairs.api';

import { handleSubmitError } from '../utils/forms';

export const UPSERT_REPAIR_FORM_MODAL_ID = 'upsertRepair';

interface UpsertRepairFormProps {
  repair?: Repair;
}

export default function UpsertRepairForm({ repair }: UpsertRepairFormProps) {
  const initialValues = {
    serialNumber: repair?.serialNumber || '',
    modelName: repair?.modelName || '',
    errorCode: repair?.errorCode || '',
    clientFirstName: repair?.clientFirstName || '',
    clientLastName: repair?.clientLastName || '',
    clientPhone: repair?.clientPhone || '',
    clientEmail: repair?.clientEmail || '',
    message: repair?.message || '',
    note: repair?.note || '',
  };

  const form = useForm({
    initialValues,
  });

  const [modelsSearch, setModelsSearch] = useState('');
  const { data: models = [], isLoading: isLoadingModels } = useGetModelsQuery({
    searchQuery: modelsSearch,
    pageLength: 10,
    page: 1,
  });

  const [errorCodesSearch, setErrorCodesSearch] = useState('');
  const { data: errorCodes = [], isLoading: isLoadingErrorCodes } =
    useGetErrorCodesQuery({
      searchQuery: errorCodesSearch,
      models: [form.values.modelName],
      pageLength: 10,
      page: 1,
    });

  const [createRepair, { isLoading: isLoadingCreate }] =
    useCreateRepairMutation();
  const [updateRepair, { isLoading: isLoadingUpdate }] =
    useUpdateRepairMutation();

  const onCancelClick = () => {
    closeModal(UPSERT_REPAIR_FORM_MODAL_ID);
  };

  const onSubmit = async (values: typeof initialValues) => {
    try {
      if (repair) {
        const data: UpdateRepairRequest = {
          id: repair?.id,
          body: {
            serialNumber: values.serialNumber,
            modelName: values.modelName,
            errorCode: values.errorCode,
            clientFirstName: values.clientFirstName,
            clientLastName: values.clientLastName,
            clientPhone: values.clientPhone,
            clientEmail: values.clientEmail,
            message: values.message,
            note: values.note,
          },
        };

        await updateRepair(data).unwrap();

        notifications.show({
          title: 'Riparazione modificata',
          message: 'Riparazione modificata con successo.',
        });
      } else {
        const data: CreateRepairRequestBody = {
          serialNumber: values.serialNumber,
          modelName: values.modelName,
          errorCode:
            values.errorCode.trim() !== '' ? values.errorCode : undefined,
          clientFirstName: values.clientFirstName,
          clientLastName: values.clientLastName,
          clientPhone: values.clientPhone,
          clientEmail: values.clientEmail,
          message: values.message.trim() !== '' ? values.message : undefined,
          note: values.note.trim() !== '' ? values.note : undefined,
        };

        await createRepair(data).unwrap();

        notifications.show({
          title: 'Riparazione inserita',
          message: 'Nuova riparazione inserita con successo.',
        });
      }

      closeModal(UPSERT_REPAIR_FORM_MODAL_ID);
    } catch (e) {
      handleSubmitError(e, form);
    }
  };

  return (
    <form onSubmit={form.onSubmit(onSubmit)}>
      <Grid>
        <Grid.Col>
          <TextInput
            required
            label="Numero seriale"
            {...form.getInputProps('serialNumber')}
          />
        </Grid.Col>
        <Grid.Col span={{ lg: 6 }}>
          <Select
            required
            label="Modello"
            disabled={isLoadingModels}
            searchable
            searchValue={modelsSearch}
            onSearchChange={setModelsSearch}
            nothingFoundMessage="Nessun modello trovato"
            data={models?.map((e) => ({ value: e.name, label: e.name }))}
            {...form.getInputProps('modelName')}
          />
        </Grid.Col>
        <Grid.Col span={{ lg: 6 }}>
          <Select
            label="Codice di errore"
            disabled={
              isLoadingErrorCodes ||
              !form.values.modelName ||
              form.values.modelName.trim() === ''
            }
            searchable
            searchValue={errorCodesSearch}
            onSearchChange={setErrorCodesSearch}
            nothingFoundMessage="Nessun codice di errore trovato"
            data={errorCodes?.map((e) => ({ value: e.code, label: e.code }))}
            {...form.getInputProps('errorCode')}
          />
        </Grid.Col>
        <Grid.Col>
          <Divider label="Informazioni cliente" labelPosition="left" mt="md" />
        </Grid.Col>
        <Grid.Col span={{ lg: 6 }}>
          <TextInput
            required
            label="Nome cliente"
            {...form.getInputProps('clientFirstName')}
          />
        </Grid.Col>
        <Grid.Col span={{ lg: 6 }}>
          <TextInput
            required
            label="Cognome cliente"
            {...form.getInputProps('clientLastName')}
          />
        </Grid.Col>
        <Grid.Col span={{ lg: 6 }}>
          <TextInput
            type="tel"
            required
            label="Telefono cliente"
            {...form.getInputProps('clientPhone')}
          />
        </Grid.Col>
        <Grid.Col span={{ lg: 6 }}>
          <TextInput
            type="email"
            required
            label="Email cliente"
            {...form.getInputProps('clientEmail')}
          />
        </Grid.Col>
        <Grid.Col>
          <Divider label="Altre informazioni" labelPosition="left" mt="md" />
        </Grid.Col>
        <Grid.Col span={{ lg: 6 }}>
          <Textarea
            rows={5}
            label="Messaggio"
            maxLength={2500}
            {...form.getInputProps('message')}
          />
        </Grid.Col>
        <Grid.Col span={{ lg: 6 }}>
          <Textarea
            rows={5}
            label="Nota"
            maxLength={2500}
            {...form.getInputProps('note')}
          />
        </Grid.Col>
      </Grid>

      {form.errors.general && (
        <Text c="red" size="xs">
          {form.errors.general}
        </Text>
      )}

      <Group justify="right" mt="xl">
        <Button
          onClick={onCancelClick}
          variant="default"
          loading={isLoadingCreate || isLoadingUpdate}
        >
          Annulla
        </Button>
        <Button type="submit" loading={isLoadingCreate || isLoadingUpdate}>
          Conferma
        </Button>
      </Group>
    </form>
  );
}

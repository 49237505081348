import 'dayjs/locale/it';

import {
  Button,
  Group,
  Stack,
  Switch,
  Text,
  TextInput,
  Textarea,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { closeModal } from '@mantine/modals';
import { notifications } from '@mantine/notifications';

import {
  CreateErrorCodeRequestBody,
  ErrorCode,
  UpdateErrorCodeRequest,
  useCreateErrorCodeMutation,
  useUpdateErrorCodeMutation,
} from '@api/errorCodes.api';

import { handleSubmitError } from '../utils/forms';
import ModelsMultiSelector from './ModelsMultiSelector';

export const UPSERT_ERROR_CODE_FORM_MODAL_ID = 'upsertErrorCode';

interface UpsertErrorCodeFormProps {
  errorCode?: ErrorCode;
}

export default function UpsertErrorCodeForm({
  errorCode,
}: UpsertErrorCodeFormProps) {
  const initialValues = {
    code: errorCode?.code || '',
    description: errorCode?.description || '',
    cause: errorCode?.cause || '',
    solution: errorCode?.solution || '',
    repairable: errorCode?.repairable || false,
    models: errorCode?.models || [],
  };

  const form = useForm({
    initialValues,
  });

  const [createErrorCode, { isLoading: isLoadingCreate }] =
    useCreateErrorCodeMutation();
  const [updateErrorCode, { isLoading: isLoadingUpdate }] =
    useUpdateErrorCodeMutation();

  const onCancelClick = () => {
    closeModal(UPSERT_ERROR_CODE_FORM_MODAL_ID);
  };

  const onSubmit = async (values: typeof initialValues) => {
    try {
      if (errorCode) {
        const data: UpdateErrorCodeRequest = {
          code: errorCode.code,
          body: {
            code: values.code,
            description: values.description,
            cause: values.cause,
            solution: values.solution,
            repairable: values.repairable,
            models: values.models,
          },
        };

        await updateErrorCode(data).unwrap();

        notifications.show({
          title: 'Codice di errore modificato',
          message: 'Codice di errore modificato con successo.',
        });
      } else {
        const data: CreateErrorCodeRequestBody = {
          code: values.code,
          description: values.description,
          cause: values.cause,
          solution: values.solution,
          repairable: values.repairable,
          models: values.models,
        };

        await createErrorCode(data).unwrap();

        notifications.show({
          title: 'Codice di errore creato',
          message: 'Nuovo codice di errore creato con successo.',
        });
      }

      closeModal(UPSERT_ERROR_CODE_FORM_MODAL_ID);
    } catch (e) {
      handleSubmitError(e, form);
    }
  };

  return (
    <form onSubmit={form.onSubmit(onSubmit)}>
      <Stack>
        <TextInput
          required
          label="Codice"
          data-autofocus
          {...form.getInputProps('code')}
        />
        <Textarea
          required
          label="Descrizione"
          rows={3}
          {...form.getInputProps('description')}
        />
        <Textarea
          required
          label="Causa"
          rows={6}
          {...form.getInputProps('cause')}
        />
        <Textarea
          label="Soluzione"
          rows={6}
          {...form.getInputProps('solution')}
        />
        <Switch
          label="Riparabile"
          checked={form.values.repairable}
          onChange={(e) =>
            form.setFieldValue('repairable', e.currentTarget.checked)
          }
        />
        <ModelsMultiSelector
          label="Modelli associati"
          {...form.getInputProps('models')}
        />
      </Stack>

      {form.errors.general && (
        <Text c="red" size="xs">
          {form.errors.general}
        </Text>
      )}

      <Group justify="right" mt="xl">
        <Button
          onClick={onCancelClick}
          variant="default"
          loading={isLoadingCreate || isLoadingUpdate}
        >
          Annulla
        </Button>
        <Button type="submit" loading={isLoadingCreate || isLoadingUpdate}>
          Conferma
        </Button>
      </Group>
    </form>
  );
}

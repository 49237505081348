import { User } from '@interfaces/auth.interface';
import {
  CountResponse,
  PaginationQueryParams,
  SearchQueryParams,
  SortQueryParams,
} from '@interfaces/common.interface';

import api from './';

export const repairStatusesArray = [
  'a0_pending',
  'inserito',
  'arrivato',
  'consegnato',
  'ritiratoPressoCliente',
  'inDiagnosi',
  'inAttesaIndicazioniCliente',
  'inAttesaApprovazionePreventivo',
  'inRiparazione',
  'speditoARiparatoreEsterno',
  'inAttesaComponenti',
  'inAttesaInverter',
  'inAttesaOffertaFornitoreComponenti',
  'inAttesaOffertaFornitoreInverter',
  'riparatoInAttesaCollaudo',
  'inCollaudo',
  'riparazioneECollaudoConclusi',
  'inAttesaPagamento',
  'inAttesaRottamazione',
  'prontoPerSpedizione',
  'prontoPerRitiro',
  'spedito',
  'ritiratoPressoNostraSede',
] as const;
export type RepairsStatuses = (typeof repairStatusesArray)[number];

export type GetRepairsSortBy = 'createdAt';

export interface CountRepairsRequestParams extends Partial<SearchQueryParams> {
  searchQuery?: string;
  status?: RepairsStatuses;
}

export interface Repair {
  id: number;
  serialNumber: string;
  modelName: string;
  errorCode: string | null;
  clientFirstName: string;
  clientLastName: string;
  clientPhone: string;
  clientEmail: string;
  message: string | null;
  note: string | null;
  currentStatus: RepairsStatuses;
  createdAt: string;
}

export interface RepairStatusRecord {
  status: RepairsStatuses;
  note: string | null;
  repairId: number;
  notifiedToClient: boolean;
  createdAt: string;
  user: User;
}

export interface CreateRepairRequestBody {
  serialNumber: string;
  modelName: string;
  errorCode?: string;
  clientFirstName: string;
  clientLastName: string;
  clientPhone: string;
  clientEmail: string;
  message?: string;
  note?: string;
}

export type UpdateRepairRequestBody = CreateRepairRequestBody;

export interface UpdateRepairRequest {
  id: number;
  body: UpdateRepairRequestBody;
}

export interface UpdateRepairStatusRequestBody {
  status: RepairsStatuses;
  notifyToClient: boolean;
  note?: string;
}

export interface UpdateRepairStatusRequest {
  id: number;
  body: UpdateRepairStatusRequestBody;
}

export interface GetRepairsRequestParams
  extends CountRepairsRequestParams,
    Partial<PaginationQueryParams>,
    Partial<SortQueryParams<GetRepairsSortBy>> {}

export interface StatusCheckRecord {
  status: RepairsStatuses;
  note: string | null;
  repairId: number;
  createdAt: string;
}

export const {
  useCountRepairsQuery,
  useGetRepairsQuery,
  useGetRepairStatusesQuery,
  useCreateRepairMutation,
  useUpdateRepairMutation,
  useUpdateRepairStatusMutation,
  useDeleteRepairMutation,
  useCheckRepairStatusQuery,
} = api.injectEndpoints({
  endpoints: (build) => ({
    countRepairs: build.query<CountResponse, CountRepairsRequestParams>({
      query: (params) => ({
        url: 'repairs/count',
        params,
      }),
      providesTags: ['repairs'],
    }),
    getRepairs: build.query<Repair[], GetRepairsRequestParams>({
      query: (params) => ({
        url: 'repairs',
        params,
      }),
      providesTags: ['repairs'],
    }),
    getRepairStatuses: build.query<RepairStatusRecord[], number>({
      query: (id) => ({
        url: `repairs/${id}/statuses`,
      }),
      providesTags: ['repairs'],
    }),
    createRepair: build.mutation<Repair, CreateRepairRequestBody>({
      query: (body) => ({
        url: 'repairs',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['repairs'],
    }),
    updateRepair: build.mutation<Repair, UpdateRepairRequest>({
      query: ({ id, body }) => ({
        url: `repairs/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['repairs'],
    }),
    updateRepairStatus: build.mutation<Repair, UpdateRepairStatusRequest>({
      query: ({ id, body }) => ({
        url: `repairs/${id}/statuses`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['repairs'],
    }),
    deleteRepair: build.mutation<Repair, number>({
      query: (id) => ({
        url: `repairs/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['repairs'],
    }),
    checkRepairStatus: build.query<StatusCheckRecord[], string>({
      query: (token) => ({
        url: `repairs/status-check/${token}`,
      }),
    }),
  }),
});

import 'dayjs/locale/it';

import { useEffect } from 'react';

import {
  Button,
  Group,
  Select,
  Stack,
  Switch,
  Text,
  Textarea,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { closeModal } from '@mantine/modals';
import { notifications } from '@mantine/notifications';
import { statusMapper } from '@utils/mappers';

import {
  Repair,
  UpdateRepairStatusRequest,
  repairStatusesArray,
  useUpdateRepairStatusMutation,
} from '@api/repairs.api';

import { handleSubmitError } from '../utils/forms';

export const UPDATE_REPAIR_STATUS_FORM_MODAL_ID = 'updateRepairStatus';

interface UpdateRepairStatusFormProps {
  repair: Repair;
}

export default function UpdateRepairStatusForm({
  repair,
}: UpdateRepairStatusFormProps) {
  const initialValues = {
    status: null,
    notifyToClient: true,
    note: '',
  };

  const form = useForm({
    initialValues,
  });

  useEffect(() => {
    if (form.values.status) {
      form.setFieldValue(
        'notifyToClient',
        statusMapper(form.values.status).notifyToClient,
      );
    }
  }, [form.values.status]);

  const [updateRepairStatus, { isLoading }] = useUpdateRepairStatusMutation();

  const onCancelClick = () => {
    closeModal(UPDATE_REPAIR_STATUS_FORM_MODAL_ID);
  };

  const onSubmit = async (values: typeof initialValues) => {
    try {
      const data: UpdateRepairStatusRequest = {
        id: repair.id,
        body: {
          status: values.status!,
          notifyToClient: values.notifyToClient,
          note: values.note.trim() !== '' ? values.note : undefined,
        },
      };

      await updateRepairStatus(data).unwrap();

      notifications.show({
        title: 'Stati aggiornato',
        message: 'Stato riparazione aggiornato con successo.',
      });

      closeModal(UPDATE_REPAIR_STATUS_FORM_MODAL_ID);
    } catch (e) {
      handleSubmitError(e, form);
    }
  };

  return (
    <form onSubmit={form.onSubmit(onSubmit)}>
      <Stack>
        <Select
          required
          label="Stato"
          data={repairStatusesArray
            .filter((status) => status !== 'a0_pending')
            .map((status) => ({
              value: status,
              label: statusMapper(status).label,
            }))}
          {...form.getInputProps('status')}
        />
        <Textarea label="Nota" {...form.getInputProps('note')} />
        <Switch
          label="Notifica cliente"
          description="Se abilitato il cliente riceverà un'email"
          checked={form.values.notifyToClient}
          {...form.getInputProps('notifyToClient')}
        />
      </Stack>

      {form.errors.general && (
        <Text c="red" size="xs" mt="md">
          {form.errors.general}
        </Text>
      )}

      <Group justify="right" mt="xl">
        <Button onClick={onCancelClick} variant="default" loading={isLoading}>
          Annulla
        </Button>
        <Button type="submit" loading={isLoading}>
          Conferma
        </Button>
      </Group>
    </form>
  );
}

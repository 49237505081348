import { NavLink } from 'react-router-dom';

import logo from '@assets/images/logo.png';
import {
  Box,
  Group,
  Menu,
  ScrollArea,
  Text,
  ThemeIcon,
  rem,
} from '@mantine/core';
import { openModal } from '@mantine/modals';
import {
  IconChevronRight,
  IconKey,
  IconLogout,
  IconUser,
  IconUserCircle,
} from '@tabler/icons-react';

import useAuth from '@hooks/useAuth';

import EditUserForm from '@components/EditUserForm';
import EditUserPasswordForm from '@components/EditUserPasswordForm';

import { linksData } from '../../../data/linksData';
import classes from './Sidebar.module.css';

export default function Sidebar() {
  const { user, logout } = useAuth();

  const handleEditUserClick = () => {
    openModal({
      title: 'Modifica profilo',
      children: <EditUserForm />,
    });
  };

  const handleEditPasswordClick = () => {
    openModal({
      title: 'Modifica password',
      children: <EditUserPasswordForm />,
    });
  };

  const links = linksData.map((item) => (
    <NavLink key={item.label} to={item.link} className={classes.link}>
      <Group justify="space-between" gap={0}>
        <Box style={{ display: 'flex', alignItems: 'center' }}>
          <ThemeIcon variant="light" size={30}>
            <item.icon style={{ width: rem(18), height: rem(18) }} />
          </ThemeIcon>
          <Box ml="md">{item.label}</Box>
        </Box>
      </Group>
    </NavLink>
  ));

  return (
    <nav className={classes.sidebar}>
      <div className={classes.header}>
        <img src={logo} alt="Logo Tuoteam" style={{ height: rem(40) }} />
      </div>

      <ScrollArea className={classes.links}>
        <div className={classes.linksInner}>{links}</div>
      </ScrollArea>

      <div className={classes.footer}>
        <Menu position="right-end" withArrow>
          <Menu.Target>
            <Group wrap="nowrap" className={classes.user}>
              <IconUserCircle size="2rem" />

              <div style={{ flex: 1 }}>
                <Text size="sm" fw={500}>
                  {user?.name}
                </Text>

                <Text c="dimmed" size="xs">
                  {user?.email}
                </Text>
              </div>

              <IconChevronRight
                style={{ width: rem(14), height: rem(14) }}
                stroke={1.5}
              />
            </Group>
          </Menu.Target>
          <Menu.Dropdown>
            <Menu.Item onClick={handleEditUserClick}>
              <Group>
                <IconUser size="1.5rem" />
                <Text c="black" size="sm">
                  Modifica profilo
                </Text>
              </Group>
            </Menu.Item>
            <Menu.Item onClick={handleEditPasswordClick}>
              <Group>
                <IconKey size="1.5rem" />
                <Text size="sm">Modifica password</Text>
              </Group>
            </Menu.Item>
            <Menu.Item onClick={logout} c="red">
              <Group>
                <IconLogout size="1.5rem" />
                <Text size="sm">Logout</Text>
              </Group>
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </div>
    </nav>
  );
}

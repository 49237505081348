import { ForwardRefExoticComponent, RefAttributes } from 'react';

import {
  Icon,
  IconBadgeTm,
  IconBatteryAutomotive,
  IconFileAlert,
  IconListDetails,
  IconProps,
  IconUsers,
} from '@tabler/icons-react';

export const linksData: {
  label: string;
  icon: ForwardRefExoticComponent<Omit<IconProps, 'ref'> & RefAttributes<Icon>>;
  link: string;
}[] = [
  {
    label: 'Riparazioni',
    icon: IconListDetails,
    link: '/',
  },
  {
    label: 'Marche',
    icon: IconBadgeTm,
    link: '/marche',
  },
  {
    label: 'Modelli',
    icon: IconBatteryAutomotive,
    link: '/modelli',
  },
  {
    label: 'Codici di errore',
    icon: IconFileAlert,
    link: '/codici-errore',
  },
  {
    label: 'Utenti',
    icon: IconUsers,
    link: '/utenti',
  },
];

import { Link, Navigate, useNavigate } from 'react-router-dom';

import { Anchor, Button, Text, TextInput, Title } from '@mantine/core';
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import { handleSubmitError } from '@utils/forms';

import { usePasswordResetRequestMutation } from '@api/passwordReset.api';

import useAuth from '@hooks/useAuth';

export default function PasswordResetRequest() {
  // ==========================================================================
  // General
  // ==========================================================================
  const { isAuthenticated } = useAuth();

  const navigate = useNavigate();

  // ==========================================================================
  // Form
  // ==========================================================================
  const initialValues = {
    email: '',
  };

  const form = useForm({
    initialValues,
  });

  const onSubmit = async (values: typeof initialValues) => {
    try {
      await passwordResetRequest(values).unwrap();

      showNotification({
        title: 'Richiesta ripristino password confermata!',
        message:
          'Se la email che hai inserito è corretta riceverai una email con le istruzioni per ripristinare la tua password.',
      });

      navigate('/');
    } catch (e) {
      handleSubmitError(e, form);
    }
  };

  // ==========================================================================
  // Api
  // ==========================================================================
  const [passwordResetRequest, { isLoading }] =
    usePasswordResetRequestMutation();

  // ==========================================================================
  // Render
  // ==========================================================================
  return isAuthenticated ? (
    <Navigate to="/ordini" replace={true} />
  ) : (
    <>
      <form
        onSubmit={form.onSubmit((values) => {
          onSubmit(values);
        })}
      >
        <Title order={2} ta="center" mb="lg">
          Recupera password
        </Title>
        <Text ta="center" size="sm" mb="lg">
          Inserisci l'email con la quale ti sei registrato e riceverai un link
          per ripristinare la tua password.
        </Text>
        <TextInput label="Email" required {...form.getInputProps('email')} />
        <Text size="sm" mt="sm">
          Sai già la tua password?{' '}
          <Anchor to="/" component={Link} size="sm">
            Accedi
          </Anchor>
        </Text>

        {form.errors.general && (
          <Text c="red" size="sm" mt="xl">
            {form.errors.general}
          </Text>
        )}
        <Button type="submit" fullWidth mt="xl" loading={isLoading}>
          Recupera password
        </Button>
      </form>
    </>
  );
}

import { Button, Group, PasswordInput, Stack, Text } from '@mantine/core';
import { useForm } from '@mantine/form';
import { closeAllModals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import { handleSubmitError } from '@utils/forms';

import { usePatchUserMutation } from '@api/users.api';

export default function EditUserPasswordForm() {
  // ==========================================================================
  // Form
  // ==========================================================================
  const initialValues = {
    oldPassword: '',
    password: '',
    passwordConfirm: '',
  };

  const form = useForm({
    initialValues,
    validate: {
      passwordConfirm: (value, values) =>
        value !== values.password ? 'Le password non corrispondono.' : null,
    },
  });

  const onSubmit = async (values: typeof initialValues) => {
    try {
      await patchUser({
        password: values.password,
        oldPassword: values.oldPassword,
      }).unwrap();

      showNotification({
        title: 'Password modificata',
        message: 'La password del tuo account è stata modificata con successo',
      });

      closeAllModals();
    } catch (e) {
      handleSubmitError(e, form);
    }
  };

  // ==========================================================================
  // Api
  // ==========================================================================
  const [patchUser, { isLoading }] = usePatchUserMutation();

  // ==========================================================================
  // Render
  // ==========================================================================
  return (
    <form
      onSubmit={form.onSubmit((values) => {
        onSubmit(values);
      })}
    >
      <Stack>
        <PasswordInput
          label="Vecchia password"
          required
          data-autofocus
          {...form.getInputProps('oldPassword')}
        />
        <PasswordInput
          label="Nuova password"
          required
          {...form.getInputProps('password')}
        />
        <PasswordInput
          label="Ripeti password"
          required
          {...form.getInputProps('passwordConfirm')}
        />
      </Stack>
      {form.errors.general && (
        <Text c="red" size="sm" mt="xl">
          {form.errors.general}
        </Text>
      )}
      <Group mt="xl" justify="end">
        <Button
          variant="default"
          onClick={() => closeAllModals()}
          loading={isLoading}
        >
          Annulla
        </Button>
        <Button type="submit" loading={isLoading}>
          Conferma
        </Button>
      </Group>
    </form>
  );
}

import { useSearchParams } from 'react-router-dom';

import { ActionIcon, TextInput } from '@mantine/core';
import { IconSearch, IconTrash } from '@tabler/icons-react';

interface SearchBarProps {
  placeholder: string;
  value?: string;
  onChange: (newValue?: string) => void;
}

export default function SearchBar({
  placeholder,
  value = '',
  onChange,
}: SearchBarProps) {
  const [searchParams, setSearchParams] = useSearchParams();

  const handleSearchChange = (value?: string) => {
    if (value && value.trim() !== '') {
      setSearchParams({ ...searchParams, search: value });
    } else {
      searchParams.delete('search');
      setSearchParams(searchParams);
    }

    onChange(value);
  };

  return (
    <TextInput
      variant="filled"
      w={{ base: '100%', md: 350 }}
      placeholder={placeholder}
      leftSection={<IconSearch size={14} />}
      value={value}
      onChange={(e) => handleSearchChange(e.target.value)}
      rightSection={
        value !== '' && (
          <ActionIcon
            variant="subtle"
            onClick={() => handleSearchChange(undefined)}
            size="sm"
          >
            <IconTrash />
          </ActionIcon>
        )
      }
    />
  );
}

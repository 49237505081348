import { useState } from 'react';

import { MultiSelect, MultiSelectProps } from '@mantine/core';

import { useGetErrorCodesQuery } from '@api/errorCodes.api';

export default function ErroCodesMultiSelector(props: MultiSelectProps) {
  const [errorCodesSearch, setErrorCodesSearch] = useState('');
  const { data: errorCodes, isLoading: isLoadingErrorCodes } =
    useGetErrorCodesQuery({
      searchQuery: errorCodesSearch,
      pageLength: 10,
      page: 1,
    });

  return (
    <MultiSelect
      disabled={isLoadingErrorCodes}
      searchable
      searchValue={errorCodesSearch}
      onSearchChange={setErrorCodesSearch}
      nothingFoundMessage="Nessun codice di errore trovato"
      data={errorCodes?.map((e) => ({ value: e.code, label: e.code }))}
      {...props}
    />
  );
}

import { useParams } from 'react-router-dom';

import {
  Center,
  Loader,
  Stack,
  Text,
  Timeline,
  Title,
  rem,
} from '@mantine/core';
import { IconCheck } from '@tabler/icons-react';
import { dateToDateHourString } from '@utils/date';
import { statusMapper } from '@utils/mappers';

import { useCheckRepairStatusQuery } from '@api/repairs.api';

export default function StatusCheck() {
  // ==========================================================================
  // General
  // ==========================================================================
  const { token } = useParams();

  // ==========================================================================
  // State
  // ==========================================================================

  // ==========================================================================
  // Api
  // ==========================================================================
  const { data = [], isLoading, error } = useCheckRepairStatusQuery(token!);

  console.log(error);

  // ==========================================================================
  // Form
  // ==========================================================================

  // ==========================================================================
  // Handlers
  // ==========================================================================

  // ==========================================================================
  // Render
  // ==========================================================================
  return (
    <Center mih="100vh">
      {isLoading ? (
        <Loader />
      ) : error ? (
        <Stack align="center">
          <Text fw="bold" size="lg">
            Token non valido
          </Text>
          <Text>Utilizza il link presente nell'email che hai ricevuto</Text>
        </Stack>
      ) : (
        <Stack gap="xl">
          <Title>Stati riparazione #{data[0].repairId}</Title>
          <Timeline
            active={data.length}
            bulletSize={30}
            styles={{ itemTitle: { fontSize: rem(18) } }}
          >
            {data.map((record) => (
              <Timeline.Item
                bullet={<IconCheck />}
                title={statusMapper(record.status).label}
                color={statusMapper(record.status).color}
              >
                <Text c="dimmed">
                  {dateToDateHourString(new Date(record.createdAt))}
                </Text>
                <Text size="sm">{record.note}</Text>
              </Timeline.Item>
            ))}
          </Timeline>
        </Stack>
      )}
    </Center>
  );
}

import {
  CountResponse,
  PaginationQueryParams,
  SearchQueryParams,
  SortQueryParams,
} from '@interfaces/common.interface';

import api from './';

export type GetModelsSortBy = 'name' | 'brandName';

export interface CountModelsRequestParams extends Partial<SearchQueryParams> {
  brandName?: string;
  errorCodes?: string[];
}

export interface Model {
  name: string;
  brandName: string;
  errorCodes: string[];
}

export interface CreateModelRequestBody {
  name: string;
  brandName: string;
  errorCodes: string[];
}

export type UpdateModelRequestBody = CreateModelRequestBody;

export interface UpdateModelRequest {
  name: string;
  body: UpdateModelRequestBody;
}

export interface GetModelsRequestParams
  extends CountModelsRequestParams,
    Partial<PaginationQueryParams>,
    Partial<SortQueryParams<GetModelsSortBy>> {}

export const {
  useCountModelsQuery,
  useGetModelsQuery,
  useCreateModelMutation,
  useUpdateModelMutation,
  useDeleteModelMutation,
} = api.injectEndpoints({
  endpoints: (build) => ({
    countModels: build.query<CountResponse, CountModelsRequestParams>({
      query: (params) => ({
        url: 'models/count',
        params,
      }),
      providesTags: ['models/errorCodes'],
    }),
    getModels: build.query<Model[], GetModelsRequestParams>({
      query: (params) => ({
        url: 'models',
        params,
      }),
      providesTags: ['models/errorCodes'],
    }),
    createModel: build.mutation<Model, CreateModelRequestBody>({
      query: (body) => ({
        url: 'models',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['models/errorCodes'],
    }),
    updateModel: build.mutation<Model, UpdateModelRequest>({
      query: ({ name, body }) => ({
        url: `models/${encodeURIComponent(name)}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['models/errorCodes'],
    }),
    deleteModel: build.mutation<Model, string>({
      query: (name) => ({
        url: `models/${encodeURIComponent(name)}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['models/errorCodes'],
    }),
  }),
});

import '@mantine/core/styles.layer.css';
import '@mantine/dates/styles.layer.css';
import '@mantine/notifications/styles.layer.css';
import 'mantine-datatable/styles.layer.css';
import 'dayjs/locale/it';

import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { RouterProvider } from 'react-router-dom';

import { Center, Loader, MantineProvider } from '@mantine/core';
import { DatesProvider } from '@mantine/dates';
import { ModalsProvider } from '@mantine/modals';
import { Notifications } from '@mantine/notifications';

import env from '@config/env';

import { useGetCurrentUserQuery } from '@api/auth.api';

import { setCredentials } from '@slices/auth.slice';

import router from '@routes/router';

import theme from './theme';

export default function App() {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const { data: user, isLoading: isLoadingGetUser } = useGetCurrentUserQuery();

  // Get user when page is reloaded
  useEffect(() => {
    (async () => {
      if (!isLoadingGetUser) {
        if (user) {
          if (env.MODE === 'production') {
            const { H } = await import('highlight.run');
            H.identify(user.email, {
              id: user.id,
              name: user.name,
            });
          }

          dispatch(setCredentials({ user }));
        }
        setIsLoading(false);
      }
    })();
  }, [dispatch, user, isLoadingGetUser]);

  return (
    <MantineProvider theme={theme} forceColorScheme="light">
      <Notifications autoClose={5000} />
      <DatesProvider settings={{ locale: 'it' }}>
        <ModalsProvider
          modalProps={{
            // zIndex: 9999,
            styles: { title: { fontSize: '1.2rem', fontWeight: '600' } },
          }}
        >
          {isLoading ? (
            <Center h="100vh">
              <Loader />
            </Center>
          ) : (
            <>
              <RouterProvider router={router} />
            </>
          )}
        </ModalsProvider>
      </DatesProvider>
    </MantineProvider>
  );
}

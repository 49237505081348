import { createBrowserRouter } from 'react-router-dom';

import PrivateRoute from '@components/PrivateRoute';
import Layout from '@components/layout/Layout';

import ActivateUser from './ActivateUser';
import Brands from './Brands';
import ErrorCodes from './ErrorCodes';
import Login from './Login';
import Models from './Models';
import NotFound from './NotFound';
import PasswordReset from './PasswordReset';
import PasswordResetRequest from './PasswordResetRequest';
import Repairs from './Repairs';
import StatusCheck from './StatusCheck';
import Users from './Users';

export const LOGIN_PATH = '/login';

const router = createBrowserRouter([
  { path: '/status-check/:token', element: <StatusCheck /> },
  {
    path: '/',
    element: <Layout />,
    children: [
      {
        index: true,
        element: <PrivateRoute element={<Repairs />} />,
      },
      {
        path: 'login',
        element: <Login />,
      },
      {
        path: 'password-reset',
        element: <PasswordResetRequest />,
      },
      {
        path: 'password-reset/:token',
        element: <PasswordReset />,
      },
      {
        path: 'users/activate/:token',
        element: <ActivateUser />,
      },
      {
        path: 'utenti',
        element: <PrivateRoute element={<Users />} />,
      },
      {
        path: 'marche',
        element: <PrivateRoute element={<Brands />} />,
      },
      {
        path: 'modelli',
        element: <PrivateRoute element={<Models />} />,
      },
      {
        path: 'codici-errore',
        element: <PrivateRoute element={<ErrorCodes />} />,
      },
      {
        path: '*',
        element: <NotFound />,
      },
    ],
  },
]);

export default router;

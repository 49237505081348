import { SigninRequest, User } from '@interfaces/auth.interface';

import api from './';

export const { useGetCurrentUserQuery, useSigninMutation, useLogoutMutation } =
  api.injectEndpoints({
    endpoints: (build) => ({
      getCurrentUser: build.query<User, void>({
        query: () => ({ url: 'auth/me' }),
      }),
      signin: build.mutation<User, SigninRequest>({
        query: (body) => ({
          url: 'auth/signin',
          method: 'POST',
          body,
        }),
      }),
      logout: build.mutation<void, void>({
        query: (body) => ({
          url: 'auth/logout',
          method: 'POST',
          body,
        }),
      }),
    }),
  });

import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { User } from '@interfaces/auth.interface';

import { RootState } from '../store';

interface AuthState {
  user: User | null;
}

const slice = createSlice({
  name: 'auth',
  initialState: { user: null } as AuthState,
  reducers: {
    setCredentials: (
      state,
      { payload: { user } }: PayloadAction<AuthState>,
    ) => {
      state.user = user;
    },
    logout: (state) => {
      state.user = null;
    },
  },
});

export const { setCredentials, logout } = slice.actions;
export const selectCurrentUser = (state: RootState) => state.auth.user;

export default slice.reducer;

import {
  RequestUserActivationRequest,
  User,
  UserPatchRequest,
} from '@interfaces/auth.interface';
import {
  CountResponse,
  PaginationQueryParams,
  SearchQueryParams,
  SortQueryParams,
} from '@interfaces/common.interface';

import api from './';

export type GetUsersSortBy = 'id' | 'name' | 'email';

export type CountUsersRequestParams = Partial<SearchQueryParams>;

export interface CreatePasswordlessUserRequestBody {
  email: string;
  name: string;
}

export interface ActivateUserRequest {
  token: string;
  body: {
    password: string;
  };
}

export interface GetUsersRequestParams
  extends CountUsersRequestParams,
    Partial<PaginationQueryParams>,
    Partial<SortQueryParams<GetUsersSortBy>> {}

export const {
  useRequestActivationMutation,
  useVerifyActivationTokenQuery,
  useActivateMutation,
  usePatchUserMutation,
  useCreatePasswordlessUserMutation,
  useActivateUserMutation,
  useDeactivateUserMutation,
  useDeleteUserMutation,
  useCountUsersQuery,
  useGetUsersQuery,
} = api.injectEndpoints({
  endpoints: (build) => ({
    requestActivation: build.mutation<void, RequestUserActivationRequest>({
      query: (body) => ({
        url: `users/activate/request`,
        method: 'POST',
        body,
      }),
    }),
    verifyActivationToken: build.query<void, string>({
      query: (token) => ({
        url: `users/activate/${token}`,
        method: 'GET',
      }),
    }),
    activate: build.mutation<void, ActivateUserRequest>({
      query: ({ token, body }) => ({
        url: `users/activate/${token}`,
        method: 'POST',
        body,
      }),
    }),
    patchUser: build.mutation<User, UserPatchRequest>({
      query: (body) => ({
        url: 'users',
        method: 'PATCH',
        body,
      }),
    }), // TODO
    createPasswordlessUser: build.mutation<
      User,
      CreatePasswordlessUserRequestBody
    >({
      query: (body) => ({
        url: 'users',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['users'],
    }),
    activateUser: build.mutation<User, number>({
      query: (id) => ({
        url: `users/${id}/activate`,
        method: 'POST',
      }),
      invalidatesTags: ['users'],
    }),
    deactivateUser: build.mutation<User, number>({
      query: (id) => ({
        url: `users/${id}/deactivate`,
        method: 'POST',
      }),
      invalidatesTags: ['users'],
    }),
    deleteUser: build.mutation<User, number>({
      query: (id) => ({
        url: `users/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['users'],
    }),
    countUsers: build.query<CountResponse, CountUsersRequestParams>({
      query: (params) => ({
        url: 'users/count',
        params,
      }),
      providesTags: ['users'],
    }),
    getUsers: build.query<User[], GetUsersRequestParams>({
      query: (params) => ({
        url: 'users',
        params,
      }),
      providesTags: ['users'],
    }),
  }),
});

import { useState } from 'react';

import { Select, SelectProps } from '@mantine/core';

import { useGetBrandsQuery } from '@api/brands.api';

export default function BrandsSelector(props: SelectProps) {
  const [brandsSearch, setBrandsSearch] = useState('');
  const { data: models, isLoading: isLoadingBrands } = useGetBrandsQuery({
    searchQuery: brandsSearch,
    pageLength: 10,
    page: 1,
  });

  return (
    <Select
      disabled={isLoadingBrands}
      searchable
      searchValue={brandsSearch}
      onSearchChange={setBrandsSearch}
      nothingFoundMessage="Nessuna marca trovata"
      data={models?.map((e) => ({ value: e.name, label: e.name }))}
      {...props}
    />
  );
}

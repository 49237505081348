import { useCallback, useState } from 'react';
import { Outlet } from 'react-router-dom';

import useAuth from '@hooks/useAuth';

import AuthenticatedLayout from './authenticatedLayout/AuthenticatedLayout';
import GuestLayout from './guestLayout/GuestLayout';

interface BackLink {
  title: string;
  to: string;
}

export interface LayoutProps {
  title?: string;
  loading?: boolean;
  backLink?: BackLink;
}

type SetLayoutProps = (props: LayoutProps) => void;

export interface LayoutContextType {
  setLayoutProps: SetLayoutProps;
}

export default function Layout() {
  const { isAuthenticated } = useAuth();

  const [title, setTitle] = useState<string>();
  const [loading, setLoading] = useState<boolean>();
  const [backLink, setBackLink] = useState<BackLink>();

  const setLayoutProps = useCallback<SetLayoutProps>(
    (props) => {
      setTitle(props.title);
      setLoading(props.loading);
      setBackLink(props.backLink);
    },
    [setTitle, setLoading, setBackLink],
  );

  return isAuthenticated ? (
    <AuthenticatedLayout title={title} loading={loading} backLink={backLink}>
      <Outlet context={{ setLayoutProps } satisfies LayoutContextType} />
    </AuthenticatedLayout>
  ) : (
    <GuestLayout>
      <Outlet />
    </GuestLayout>
  );
}

import { Anchor, Group, Text } from '@mantine/core';

import { version } from '../../../package.json';

interface FooterProps {
  innerRef?: React.LegacyRef<HTMLElement>;
}

export default function Footer({ innerRef }: FooterProps) {
  // ==========================================================================
  // Render
  // ==========================================================================
  return (
    <footer ref={innerRef}>
      <Group px="md" pt="xl" pb="sm" justify="space-between">
        <Text size="xs">
          Developed by{' '}
          <Anchor
            size="xs"
            href="https://www.miroirstudio.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Miroir Studio
          </Anchor>
        </Text>
        <Text size="xs" c="dimmed">
          v{version}
        </Text>
      </Group>
    </footer>
  );
}

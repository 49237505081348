import { Group, Loader, Text, Timeline, Tooltip, rem } from '@mantine/core';
import {
  IconCheck,
  IconInfoSquareRounded,
  IconMailForward,
} from '@tabler/icons-react';
import { dateToDateHourString } from '@utils/date';
import { statusMapper } from '@utils/mappers';

import { useGetRepairStatusesQuery } from '@api/repairs.api';

export const REPAIR_STATUSES_HISTORY_MODAL_ID = 'repairStatusesHistory';

interface RepairStatusesHistoryProps {
  repairId: number;
}

export default function RepairStatusesHistory({
  repairId,
}: RepairStatusesHistoryProps) {
  // ==========================================================================
  // Api
  // ==========================================================================
  const { data = [], isLoading } = useGetRepairStatusesQuery(repairId);

  // ==========================================================================
  // Render
  // ==========================================================================
  return isLoading ? (
    <Loader />
  ) : (
    <Timeline
      active={data.length}
      bulletSize={30}
      styles={{ itemTitle: { fontSize: rem(16) } }}
    >
      {data.map((record) => (
        <Timeline.Item
          key={record.createdAt}
          title={
            <Group gap="xs">
              {statusMapper(record.status).label}
              {record.notifiedToClient && (
                <Tooltip label="Notificato al cliente">
                  <IconMailForward />
                </Tooltip>
              )}
              {record.note && (
                <Tooltip label={record.note} w="20rem" multiline>
                  <IconInfoSquareRounded />
                </Tooltip>
              )}
            </Group>
          }
          bullet={<IconCheck />}
          color={statusMapper(record.status).color}
        >
          <Text c="dimmed">
            {dateToDateHourString(new Date(record.createdAt))}{' '}
            {record.user && ` - ${record.user.name}`}
          </Text>
        </Timeline.Item>
      ))}
    </Timeline>
  );
}

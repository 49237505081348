import { Button, Group, Stack, Text, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { closeAllModals, openConfirmModal } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import { handleSubmitError } from '@utils/forms';

import { useCreatePasswordlessUserMutation } from '@api/users.api';

export default function CreatePasswordlessUserModal() {
  // ==========================================================================
  // General
  // ==========================================================================

  // ==========================================================================
  // State
  // ==========================================================================

  // ==========================================================================
  // Api
  // ==========================================================================
  const [createUser, { isLoading }] = useCreatePasswordlessUserMutation();

  // ==========================================================================
  // Form
  // ==========================================================================
  const initialValues = {
    email: '',
    name: '',
  };

  const form = useForm({
    initialValues,
  });

  // ==========================================================================
  // Handlers
  // ==========================================================================
  const onSubmit = async (values: typeof initialValues) => {
    openConfirmModal({
      title: 'Creazione utente',
      children:
        "Stai per creare un nuovo utente amministratore. L'utente potrà entrare nell'area riservata e gestire riparazioni, modelli, errori e utenti.\nContinuare?",
      labels: { confirm: 'Conferma', cancel: 'Annulla' },
      onConfirm: async () => {
        try {
          await createUser(values).unwrap();

          showNotification({
            title: 'Utente creato',
            message:
              "L'utente riceverà un'email con le istruzioni per completare la registrazione",
          });

          closeAllModals();
        } catch (error) {
          handleSubmitError(error, form);
        }
      },
    });
  };

  // ==========================================================================
  // Render
  // ==========================================================================
  return (
    <form
      onSubmit={form.onSubmit((values) => {
        onSubmit(values);
      })}
    >
      <Stack>
        <TextInput
          required
          label="Email"
          data-autofocus
          {...form.getInputProps('email')}
        />
        <TextInput
          required
          label="Nominativo"
          {...form.getInputProps('name')}
        />
      </Stack>
      {form.errors.general && (
        <Text c="red" size="sm" mt="xl">
          {form.errors.general}
        </Text>
      )}
      <Group mt="xl" justify="end">
        <Button type="submit" loading={isLoading}>
          Conferma
        </Button>
      </Group>
    </form>
  );
}

import { Group, Menu, Text, rem } from '@mantine/core';
import { openModal } from '@mantine/modals';
import {
  IconKey,
  IconLogout,
  IconUser,
  IconUserCircle,
} from '@tabler/icons-react';

import useAuth from '@hooks/useAuth';

import EditUserForm from '@components/EditUserForm';
import EditUserPasswordForm from '@components/EditUserPasswordForm';

import logo from '@assets/images/logo.png';

import classes from './Header.module.css';

export default function Header() {
  const { logout } = useAuth();

  const handleEditUserClick = () => {
    openModal({
      title: 'Modifica profilo',
      children: <EditUserForm />,
    });
  };

  const handleEditPasswordClick = () => {
    openModal({
      title: 'Modifica password',
      children: <EditUserPasswordForm />,
    });
  };

  return (
    <header className={classes.header}>
      <Group justify="space-between">
        <img src={logo} alt="Logo Tuoteam" style={{ height: rem(60) }} />

        <Group>
          <Menu withArrow>
            <Menu.Target>
              <IconUserCircle size="2rem" />
            </Menu.Target>
            <Menu.Dropdown>
              <Menu.Item onClick={handleEditUserClick}>
                <Group>
                  <IconUser size="1.5rem" />
                  <Text c="black" size="sm">
                    Modifica profilo
                  </Text>
                </Group>
              </Menu.Item>
              <Menu.Item onClick={handleEditPasswordClick}>
                <Group>
                  <IconKey size="1.5rem" />
                  <Text size="sm">Modifica password</Text>
                </Group>
              </Menu.Item>
              <Menu.Item onClick={logout} c="red">
                <Group>
                  <IconLogout size="1.5rem" />
                  <Text size="sm">Logout</Text>
                </Group>
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
          {/* <IconBadge
            tooltipLabel="Notifiche"
            badgeText={
              notificationsCount.count !== 0
                ? notificationsCount.count.toString()
                : null
            }
            to="/notifiche"
          /> */}
        </Group>
      </Group>
    </header>
  );
}

import { Button, Group, Stack, Text, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { closeAllModals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import { handleSubmitError } from '@utils/forms';

import useAuth from '@hooks/useAuth';

export default function EditUserForm() {
  // ==========================================================================
  // General
  // ==========================================================================
  const { user, patch, isLoading } = useAuth();

  // ==========================================================================
  // Form
  // ==========================================================================
  const initialValues = {
    email: user?.email,
    name: user?.name,
  };

  const form = useForm({
    initialValues,
  });

  const onSubmit = async (values: typeof initialValues) => {
    try {
      await patch(values);

      showNotification({
        title: 'Profilo modificato',
        message:
          'Le informazioni del tuo profilo sono state modificate con successo',
      });

      closeAllModals();
    } catch (e) {
      handleSubmitError(e, form);
    }
  };

  // ==========================================================================
  // Render
  // ==========================================================================
  return (
    <form
      onSubmit={form.onSubmit((values) => {
        onSubmit(values);
      })}
    >
      <Stack>
        <TextInput
          type="email"
          label="Email"
          required
          {...form.getInputProps('email')}
        />
        <TextInput label="Nome" required {...form.getInputProps('name')} />
      </Stack>
      {form.errors.general && (
        <Text c="red" size="sm" mt="xl">
          {form.errors.general}
        </Text>
      )}
      <Group mt="xl" justify="end">
        <Button
          variant="default"
          onClick={() => closeAllModals()}
          loading={isLoading}
        >
          Annulla
        </Button>
        <Button type="submit" loading={isLoading}>
          Conferma
        </Button>
      </Group>
    </form>
  );
}

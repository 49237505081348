import { useState } from 'react';

import { MultiSelect, MultiSelectProps } from '@mantine/core';

import { useGetModelsQuery } from '@api/models.api';

export default function ModelsMultiSelector(props: MultiSelectProps) {
  const [modelsSearch, setModelsSearch] = useState('');
  const { data: models, isLoading: isLoadingModels } = useGetModelsQuery({
    searchQuery: modelsSearch,
    pageLength: 10,
    page: 1,
  });

  return (
    <MultiSelect
      disabled={isLoadingModels}
      searchable
      searchValue={modelsSearch}
      onSearchChange={setModelsSearch}
      nothingFoundMessage="Nessun modello trovato"
      data={models?.map((e) => ({ value: e.name, label: e.name }))}
      {...props}
    />
  );
}

import { NavLink } from 'react-router-dom';

import { Stack, ThemeIcon, rem } from '@mantine/core';

import { linksData } from '../../../data/linksData';
import classes from './BottomNavbar.module.css';

export default function BottomNavbar() {
  const links = linksData.map((item) => (
    <NavLink key={item.label} to={item.link} className={classes.link}>
      <Stack align="center" gap="xs">
        <ThemeIcon variant="light" size={30}>
          <item.icon style={{ width: rem(18), height: rem(18) }} />
        </ThemeIcon>
        {/* <span className={classes.linkText}>{item.label}</span> */}
      </Stack>
    </NavLink>
  ));

  return <nav className={classes.navbar}>{links}</nav>;
}
